import { addDays, Calendar, defaultCalendarStrings, ICalendarDayGridStyles, IProcessedStyleSet, PrimaryButton, Spinner, SpinnerSize, Stack, StackItem, Text } from "@fluentui/react";
import { FC, useEffect, useState } from "react";
import { Slot } from "../../Models/Reviews/Slot";
import { Team } from "../../Models/Team";
import { TimeZoneConversionDTO } from "../../Models/TimeZones/TimeZoneConversionDTO";
import MeetingsService from "../../Services/MeetingsService";
import { getDateFormat, getDateTimeFromDateAndTimeAMPM, getTimeFormat } from "../../Utilities/dateutilities";
import { MeetingTimeCalloutComponent } from "./MeetingTimeCalloutComponent";
import { DisplayMessageType, MessageComponent, useMessages } from "./MessageComponent";
import AdminServices from "../../Services/AdminServices";

interface IAvailableSlotsComponent {
    id?: string
    reviewTeam: Team,
    slotButtonText: string,
    onSlotSelcted: (selecteSlot: Slot) => void,
}

export const AvailableSlotsComponent: FC<IAvailableSlotsComponent> = (props: IAvailableSlotsComponent) => {
    let meetingsService: MeetingsService = new MeetingsService();
    let adminService: AdminServices = new AdminServices();

    const stackTokens = { childrenGap: 10, };

    const [reviewTeamId, reviewTeamIdSet] = useState<string>();
    const [isLoadingSlots, isLoadingSlotsSet] = useState<boolean>(false);
    const [isLoadingLocalTimeForSlots, isLoadingLocalTimeForSlotsSet] = useState<boolean>(false);

    const [dataAvailableSlots, dataAvailableSlotsSet] = useState<Slot[]>([]);
    const [dataAvailableSlotsByLocalTime, dataAvailableSlotsByLocalTimeSet] = useState<Slot[]>([]);
    useEffect(() => {
            loadUserLocaltimeForAvailableSlots(dataAvailableSlots);
    }, [dataAvailableSlots])
    const [dataAvailableSlotsByDay, dataAvailableSlotsByDaySet] = useState<Slot[]>()
    const [selectedDate, selectedDateSet] = useState<Date | undefined>();
    const onSelectDate = (date: Date, selectedDateRangeArray?: Date[]): void => {
        selectedDateSet(date);

    }
    useEffect(() => {
        if (selectedDate) {
            dataAvailableSlotsByDaySet([]);
            var dateStr = getDateFormat(selectedDate);
            const distantFuture = new Date(8640000000000000);
            dataAvailableSlotsByDaySet(dataAvailableSlotsByLocalTime
                .sort((a, b) => {
                    let dateA: Date = a?.meetingsData?.browserLocalTime ? new Date(a.meetingsData.browserLocalTime) : distantFuture;
                    let dateB: Date = b?.meetingsData?.browserLocalTime ? new Date(b.meetingsData.browserLocalTime) : distantFuture;
                    return dateA.getTime() - dateB.getTime();
                }).filter(x => getDateFormat(x.meetingsData?.browserLocalTime) == dateStr));

        }

    }, [selectedDate])

    const loadAvailableMeetingSlots = async (reviewTeamId: string) => {
        displayMessagesSet([]);
        isLoadingSlotsSet(true);
        if (reviewTeamId && reviewTeamId.length > 0) {
            var slotsResult = await meetingsService.getAvailableSlots(reviewTeamId);
            if (slotsResult && !slotsResult.hasErrors && slotsResult.result && slotsResult.result.length > 0) {
                var openSlotList = slotsResult.result.filter(x => x.meetingsData && x.meetingsData.dateOfReview && new Date(x.meetingsData?.dateOfReview) > new Date());
                if (openSlotList)
                {
                    dataAvailableSlotsSet(slotsResult.result.filter(x => x.meetingsData && x.meetingsData.dateOfReview && new Date(x.meetingsData?.dateOfReview) > new Date()));
                }
                else
                {
                    displayMessagesSet([{ message: "No Free slots available", messageType: DisplayMessageType.Information }]);
                }
            }
            else if (slotsResult && !slotsResult.hasErrors && slotsResult.result && slotsResult.result.length == 0) {
                displayMessagesSet([{ message: "No Free slots available", messageType: DisplayMessageType.Information }]);
            }
            else {
                displayMessagesSet([{ message: "Could not load the slots widget.", messageType: DisplayMessageType.Error }]);
            }
        }

        isLoadingSlotsSet(false);
    }

    useEffect(() => {

        if (props.reviewTeam && props.reviewTeam.id && props.reviewTeam.id !== reviewTeamId) {
            dataAvailableSlotsSet([]);
            dataLocalTimeForDateSlotsSet([]);
            reviewTeamIdSet(props.reviewTeam.id);
            loadAvailableMeetingSlots(props.reviewTeam.id);
        }
    }, [props])

    const [dataLocalTimeForDateSlots, dataLocalTimeForDateSlotsSet] = useState<TimeZoneConversionDTO[]>([]);
    const loadUserLocaltimeForAvailableSlots = async (availableOriginalSlots: Slot[]) => {
        isLoadingLocalTimeForSlotsSet(true);
        var tConversionTimes: TimeZoneConversionDTO[] = [];
        availableOriginalSlots.forEach(slot => {
            if (slot
                && slot.meetingsData
                && slot.meetingsData.startTime
                && slot.meetingsData.startTime.timeAMPM
                && slot.meetingsData.timeZone
                && slot.meetingsData.timeZone.timeZoneId
                && slot.meetingsData.dateOfReview) {
                var scheduledDateTime = new Date(slot.meetingsData.dateOfReview);
                var scheduledDateTimeOffset = scheduledDateTime.getTimezoneOffset();
                var scheduledTimeZoneId = slot.meetingsData.timeZone.timeZoneId;

                tConversionTimes.push({
                    sourceTimeZone: scheduledTimeZoneId,
                    clientReferenceId: slot.clientReferenceId,
                    sourceDateTime: scheduledDateTime,
                    clientOffSetMinutes: (new Date(slot.meetingsData.dateOfReview)).getTimezoneOffset(),
                });
            }
        });

        if (tConversionTimes.length > 0) {
            var localTimesResponse = await meetingsService.convertTimeZoneToLocal(tConversionTimes);
            if (localTimesResponse && localTimesResponse.length > 0) {
                // var dateStr = getDateFormat(selectedDate);
                // var todaySlots = localTimesResponse.filter(x => getDateFormat(x.destinationDateTime) == dateStr)
                const distantFuture = new Date(8640000000000000);
                var sortedDates = localTimesResponse.sort((a, b) => {
                    let dateA: Date = a?.destinationDateTime ? new Date(a.destinationDateTime) : distantFuture;
                    let dateB: Date = b?.destinationDateTime ? new Date(b.destinationDateTime) : distantFuture;
                    return dateA.getTime() - dateB.getTime();
                });

                if (reviewTeamId && reviewTeamId.length > 0) 
                {
                    var bufferDaysValue = 0;
                    var teamMetadataResponse = await adminService.fetchTeamMetadataById(reviewTeamId);
                    if(teamMetadataResponse && teamMetadataResponse.result && !teamMetadataResponse.hasErrors)
                    {
                        if(teamMetadataResponse.result.teamMetadata)
                        {
                            bufferDaysValue = teamMetadataResponse.result.teamMetadata.bufferDays == null ? 0 : teamMetadataResponse.result.teamMetadata.bufferDays;
                        }
                    }

                    //add buffer days to show open slots
                    var date = new Date();
                    console.log("bufferDays value", bufferDaysValue);
                    date.setDate(date.getDate() + bufferDaysValue);   
                    sortedDates = sortedDates.filter(x => new Date(x.destinationDateTime!).setHours(0,0,0,0) > date.setHours(0,0,0,0));
                    console.log("sortedDates ", sortedDates);
                    dataLocalTimeForDateSlotsSet(sortedDates);
                }
            }

        }

        isLoadingLocalTimeForSlotsSet(false);
    }
    useEffect(() => {
        console.log("dataLocalTimeForDateSlots ", dataLocalTimeForDateSlots);
        dataAvailableSlots.forEach(x => {
            var localTimeObj = dataLocalTimeForDateSlots.filter(y => y.clientReferenceId == x.clientReferenceId);
            if (localTimeObj && localTimeObj.length > 0 && localTimeObj[0]) {
                x.slotDateLocal = localTimeObj[0].destinationDateTime;
                if (x.meetingsData) {
                    console.log(localTimeObj[0].destinationDateTime);
                    x.meetingsData.browserLocalTime = localTimeObj[0].destinationDateTime;
                    //add 24hrs buffer for booking new slots
                    // if (x.meetingsData.browserLocalTime)
                    // {
                    //     x.meetingsData.browserLocalTime = addDays(new Date(x.meetingsData.browserLocalTime), -1);
                    // }

                    //remove today from dataAvailableSlots
                }
            }
        });
        
        dataAvailableSlotsByLocalTimeSet(dataAvailableSlots);
    }, [dataLocalTimeForDateSlots])
    const onChangeSlotClick = async (selectedSlotId: string | undefined) => {
        var selectedSlot = dataAvailableSlots.filter(x => x.clientReferenceId == selectedSlotId);
        if (selectedSlot && selectedSlot.length > 0 && selectedSlot[0]) {
            props.onSlotSelcted(selectedSlot[0]);
        }

    }

    // // if (noSlotsAvailable) {
    // //     return <div className="row">
    // //         <div className="d-flex">
    // //             <Text className="mx-auto">No free slots available.</Text>
    // //         </div>
    // //     </div>
    // // }

    // // if (isComponentLoadError) {
    // //     return <div className="row">
    // //         <div className="d-flex">
    // //             <Text className="mx-auto">Could not load the slots widget.</Text>
    // //         </div>
    // //     </div>
    // // }

    const [displayMessages, displayMessagesSet] = useMessages();

    return (
        <div className="row">
            {
                isLoadingSlots &&
                <Spinner size={SpinnerSize.large} label="Loading slots." />
            }
            <div className="row">
                <div className="d-flex">
                    <div className="mx-auto">
                        <MessageComponent messages={displayMessages} />
                    </div>
                </div>
            </div>
            {
                dataAvailableSlotsByLocalTime && dataAvailableSlotsByLocalTime.length > 0 &&
                <div>
                    <div className="row">

                        <Calendar
                            showMonthPickerAsOverlay
                            highlightSelectedMonth
                            showGoToToday={false}
                            onSelectDate={onSelectDate}
                            value={selectedDate}
                            // Calendar uses English strings by default. For localized apps, you must override this prop.
                            strings={defaultCalendarStrings}
                            calendarDayProps={
                                {
                                    customDayCellRef: (element: HTMLElement,
                                        date: Date,
                                        classNames: IProcessedStyleSet<ICalendarDayGridStyles>,
                                    ) => {
                                        if (element) {
                                            if (dataAvailableSlotsByLocalTime.some(x => getDateFormat(x.meetingsData?.browserLocalTime) == getDateFormat(date))) {
                                                element.title = 'Slots available on ' + getDateFormat(date);
                                                (element.children[0] as HTMLButtonElement).style.color = 'white';
                                                (element.children[0] as HTMLButtonElement).style.background = 'olivedrab';
                                            }
                                            else {
                                                (element.children[0] as HTMLButtonElement).disabled = true
                                            }
                                        }
                                    },
                                }
                            }

                        />

                    </div>
                    <div className="row">
                        {
                            isLoadingLocalTimeForSlots &&
                            <Spinner size={SpinnerSize.large} label={"Loading slots for " + getDateFormat(selectedDate)} />
                        }
                    </div>
                    <div className="row">
                        {
                            dataAvailableSlotsByDay && dataAvailableSlotsByDay.length > 0 &&
                            dataAvailableSlotsByDay.map(x => {
                                //let originalSlot = dataAvailableSlots.filter(z => z.clientReferenceId == x.clientReferenceId);
                                return (
                                    <div className="row" style={{ marginTop: 5 }} key={"availablelocaltime_" + x.clientReferenceId}>
                                        <div className="col-6">
                                            <Stack horizontal tokens={stackTokens}>
                                                <StackItem>
                                                    <Text variant="medium"> {getDateFormat(x.meetingsData?.browserLocalTime)}  - {getTimeFormat(x.meetingsData?.browserLocalTime)} &nbsp;&nbsp;({x.meetingsData?.is1HourSlot ? "1hr" : "30 min"})</Text>
                                                </StackItem>
                                                <StackItem>
                                                    <MeetingTimeCalloutComponent meetingData={x.meetingsData} scheduleName={x.scheduleName} />
                                                </StackItem>
                                            </Stack>



                                        </div>
                                        <div className="col-6">
                                            {
                                                <PrimaryButton label={props.slotButtonText} text={props.slotButtonText} onClick={() => { onChangeSlotClick(x.clientReferenceId); }} />
                                            }
                                        </div>
                                    </div>
                                )
                            })

                        }
                    </div>
                </div>
            }
        </div>
    );
}