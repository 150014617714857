import { Announced, Callout, DefaultButton, elementContains, FontIcon, IChoiceGroupOption, Label, MessageBar, MessageBarType, Pivot, PivotItem, PrimaryButton, Spinner, SpinnerSize, Stack, StackItem, Text, TextField } from "@fluentui/react";
import { FC, useEffect, useRef, useState } from "react";
import { useBoolean, useId } from '@fluentui/react-hooks';
import { AvailableSlotsComponent } from "../../Components/Common/AvailableSlotsComponent";
import HeadingComponent from "../../Components/Common/HeadingComponent";
import { ServiceReviewTeamsComponent } from "../../Components/Common/ServiceReviewTeamsComponent";
import { ServiceTreeServicePickerComponent } from "../../Components/Common/ServiceTreeServicePickerComponent";
import { UserServicesComponent } from "../../Components/Common/UserServicesComponent";
import { Slot } from "../../Models/Reviews/Slot";
import ServiceTreeService from "../../Models/ServiceTreeService";
import { Team } from "../../Models/Team";
import { getDateFormat, getTimeFormat } from "../../Utilities/dateutilities";
import { SpinnerPrimaryButton } from "../../Components/Common/SpinnerPrimaryButton";
import { ReviewTypeComponent } from "../../Components/Common/ReviewTypeComponent";
import { MeetingTimeCalloutComponent } from "../../Components/Common/MeetingTimeCalloutComponent";
import { ScheduleMeetingCreate } from "../../Models/Reviews/ScheduleMeetingCreate";
import MeetingsService from "../../Services/MeetingsService";
import { Review } from "../../Models/Reviews/Review";
import { ServiceMemberPeoplePickerComponent } from "../../Components/Common/ServiceMemberPeoplePickerComponent";
import UserProfile from "../../Models/UserProfile";
import { TechnologyTagsComponent } from "../../Components/Common/TechnologyTagsComponent";
import { TechTag } from "../../Models/TechTag";
import { MeetingRequestorComponent } from "../../Components/Common/MeetingRequestorComponent";
import { UploadOrLinkArtifactsComponent } from "../../Components/Common/UploadOrLinkArtifactsComponent";
import { LinkedArtifact } from "../../Models/TMPArtifacts/LinkedArtifact";
import { ArtifactService } from "../../Services/ArtifactService";
import { ArtifactDownload } from "../../Models/TMPArtifacts/ArtifactDownload";
import { ScheduleMeetingMetadataUpdate } from "../../Models/Reviews/ScheduleMeetingMetadataUpdate";
import { PreviewQuestionnaire } from "../Questionnaire/PreviewQuestionnaire";
import { QuestionnaireService } from "../../Services/QuestionnaireService";
import { Questions } from "../../Models/Questionnaire/Question";
import { Link, useNavigate } from "react-router-dom";
import { QuestionType } from "../../Models/Questionnaire/QuestionType";
import { QuestionResponse } from "../../Models/Reviews/QuestionResponses";
import { Questionnaire } from "../../Models/Questionnaire/Questionnaire";
import { ErrorCode } from "../../Models/common/Enums";
import { DisplayMessageType, MessageComponent, useMessages } from "../../Components/Common/MessageComponent";
import { ReviewTypeStrings } from "../../Models/Constants/ReviewTypeStrings";
import { Artifact } from "../../Models/TMPArtifacts/Artifact";
import { FileTypeLogoComponent } from "../../Components/Common/FileTypeLogoComponent";
import { ArtifactTypeStrings } from "../../Models/Constants/ArtifactTypeStrings";
import { TeamMetadata } from "../../Models/TeamMetadata";
import { useNavigationExitPrompt } from "../../Components/Common/useNavigationExitPrompt";
import { SpinnerDefaultButton } from "../../Components/Common/SpinnerDefaultButton";

export const ScheduleReviewPage: FC = (): JSX.Element => {
    let reviewService = new MeetingsService();
    let artifactService = new ArtifactService();
    let queService = new QuestionnaireService();

    const stackTokens = { childrenGap: 10, };
    const navigate = useNavigate();

    const [pivotIndex, pivotIndexSet] = useState<string>("pivot_0");
    useEffect(() => {
        if (pivotIndex === "pivot_1") {
            preventNavigationSet(true);
        }

        if (pivotIndex === "pivot_3") {
            preventNavigationSet(false);
        }
    }, [pivotIndex]);
    const [review, reviewSet] = useState<Review>();

    const [dataSelectedServiceTreeService, dataSelectedServiceTreeServiceSet] = useState<ServiceTreeService>();
    const [dataSelectedOtherServiceTreeService, dataSelectedOtherServiceTreeServiceSet] = useState<ServiceTreeService>();
    const [selectedServiceTreeService, selectedServiceTreeServiceSet] = useState<ServiceTreeService>();
    useEffect(() => {
        if (dataSelectedServiceTreeService && dataSelectedServiceTreeService.serviceId && dataSelectedServiceTreeService.serviceId.length > 0) {
            showOtherServiceSelectorSet(false);
            dataSelectedOtherServiceTreeServiceSet(undefined);
            selectedServiceTreeServiceSet(dataSelectedServiceTreeService);
        }
        else {
            showOtherServiceSelectorSet(true);
        }
    }, [dataSelectedServiceTreeService]);
    useEffect(() => {
        if (dataSelectedOtherServiceTreeService
            && dataSelectedOtherServiceTreeService.serviceId
            && dataSelectedOtherServiceTreeService.serviceId.length > 0) {
            dataSelectedServiceTreeServiceSet(undefined);
            selectedServiceTreeServiceSet(dataSelectedOtherServiceTreeService);
        }
        else {
            showOtherServiceSelectorSet(false);
        }
    }, [dataSelectedOtherServiceTreeService]);

    const [showOtherServiceSelector, showOtherServiceSelectorSet] = useState<boolean>(false);
    const [dataSelectedReviewTeam, dataSelectedReviewTeamSet] = useState<Team>();
    const [dataSelectedSlot, dataSelectedSlotSet] = useState<Slot>();
    const [reviewType, reviewTypeSet] = useState<string>();
    const [dataOtherUsers, dataOtherUsersSet] = useState<UserProfile[]>([]);
    const [selectedOtherInteractionServiceTreeServices, selectedOtherInteractionServiceTreeServicesSet] = useState<ServiceTreeService[]>([]);
    const [selectedTechnologyTags, selectedTechnologyTagsSet] = useState<TechTag[]>([]);
    const [requestorUerProfile, requestorUerProfileSet] = useState<UserProfile>();

    const [teamMetadata, teamMetadataSet] = useState<TeamMetadata>();

    const [isFetchingDownloadableArtifacts, isFetchingDownloadableArtifactsSet] = useState<boolean>(false);
    const [dataDownloadableArtifacts, dataDownloadableArtifactsSet] = useState<Artifact[]>([]);

    const [teamQuestionsList, setTeamQuestionsList] = useState<Questions[]>();
    const [questionnaireId, setQuestionnaireId] = useState<string>();


    const [additionalRequestDetails, additionalRequestDetailsSet] = useState<string>();
    const onAdditionalDetailsChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        additionalRequestDetailsSet(newValue);
    }

    const questionResponsesRef = useRef<Questions[]>([]);

    const [boldTeamDescription, boldTeamDescriptionSet] = useState<string>();
    const [remainingTeamDescription, remainingTeamDescriptionSet] = useState<string>();

    useEffect(() => {
        if (dataSelectedReviewTeam && dataSelectedReviewTeam.teamDescription) {
            var tempDescription = dataSelectedReviewTeam.teamDescription;
            var tempBold = tempDescription.substring(
                tempDescription.indexOf("*") + 1,
                tempDescription.lastIndexOf("*")
            );
            boldTeamDescriptionSet(tempBold);
            var n = tempDescription.lastIndexOf('*');
            var regularDescription = tempDescription.substring(n + 1);
            remainingTeamDescriptionSet(regularDescription);
        }
    }, [dataSelectedReviewTeam])

    useEffect(() => {
        if (dataSelectedReviewTeam && dataSelectedReviewTeam.id) {
            loadTeamMetadata(dataSelectedReviewTeam.id);
            if (dataSelectedReviewTeam.teamMetadata?.enableQuestionnaire) {

                fetchQuestionnaire(dataSelectedReviewTeam.id);
            }
            else {
                setTeamQuestionsList([]);
            }
            displayMessagesSet([]);
        }
    }, [dataSelectedReviewTeam])

    const fetchQuestionnaire = async (teamId: string) => {
        var queData = await queService.fetchQuestionnaire(teamId);
        if (queData && !queData.hasErrors && queData.result) {
            setTeamQuestionsList(queData.result.questions);
            setQuestionnaireId(queData.result.id);
        }
        else {
            setTeamQuestionsList([]);
        }
    }

    const loadTeamMetadata = async (teamId: string) => {
        var response = await reviewService.getTeamMetaData(teamId);
        if (response && !response.hasErrors && response.result) {
            teamMetadataSet(response.result);
        }
        else {
            if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
                displayMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
            }
            else {
                displayMessagesSet([{ message: "The teammetadata details could not be fetched", messageType: DisplayMessageType.Error }]);
            }
        }
    }

    const loadArtifactsForDownload = async (linkedArtifacts: LinkedArtifact[] | undefined) => {
        isFetchingDownloadableArtifactsSet(true);
        if (linkedArtifacts && linkedArtifacts.length > 0) {
            var newArtifacts = linkedArtifacts.filter(x => !dataDownloadableArtifacts.some(y => y.artifactId === x.tmReviewLink?.artifactId));
            console.log(dataDownloadableArtifacts);
            console.log(linkedArtifacts);
            console.log("new aartifacts")
            console.log(newArtifacts);
            var response = await artifactService.fetchArtifactsForDownload(newArtifacts);
            if (response && !response.hasErrors && response.result && response.result.length > 0) {
                console.log(response.result);
                dataDownloadableArtifactsSet([...dataDownloadableArtifacts, ...response.result]);
            }
        }
        isFetchingDownloadableArtifactsSet(false);
    }

    const onNextClick = async () => {
        displayMessagesSet([]);
        if (selectedServiceTreeService === undefined || selectedServiceTreeService.serviceId === undefined || selectedServiceTreeService.serviceId.length == 0) {
            displayMessagesSet([{ message: "Please select a service", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (dataSelectedReviewTeam === undefined || dataSelectedReviewTeam.id === undefined || dataSelectedReviewTeam.id.length == 0) {
            displayMessagesSet([{ message: "Please select the review team", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (reviewType === undefined || reviewType.length === 0) {
            displayMessagesSet([{ message: "Please select the review type", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (dataSelectedSlot === undefined || dataSelectedSlot.id === undefined || dataSelectedSlot.id.length == 0 || dataSelectedSlot.partitionKey === undefined || dataSelectedSlot.partitionKey.length === 0) {
            displayMessagesSet([{ message: "Please select the slot in the schedules. if there are no slots available kindly check later.", messageType: DisplayMessageType.Warning }]);
            return;
        }

        let dataReview: ScheduleMeetingCreate = {
            service: selectedServiceTreeService,
            securityReviewTeam: dataSelectedReviewTeam,
            reviewType: reviewType,
            slot: dataSelectedSlot,
        }

        var response = await reviewService.createScheduleMeeting(dataReview);
        if (response && !response.hasErrors && response.result) {
            reviewSet(response.result);
            displayMessagesSet([]);
            pivotIndexSet("pivot_1");

        }
        else {
            if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
                displayMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
            }
            else {
                displayMessagesSet([{ message: "The review could not be scheduled.", messageType: DisplayMessageType.Error }]);
            }
        }
    }

    const onNextMetadataNextClick = async () => {
        var mandateTmFile = false;
        if (teamMetadata && teamMetadata.mandateTMFile) {
            mandateTmFile = teamMetadata.mandateTMFile;
        }
        
        displayMessagesSet([]);
        if (reviewType === ReviewTypeStrings.ThreatModelReview && (dataDownloadableArtifacts === undefined || dataDownloadableArtifacts.length === 0)) {
            if (mandateTmFile) {
                displayMessagesSet([{ message: "Please attach / link a threat model file for Threat modeling review.", messageType: DisplayMessageType.Warning }]);
            }
            else {
                displayMessagesSet([{ message: "Please attach / link artifact for Threat modeling review.", messageType: DisplayMessageType.Warning }]);
            }
            return;
        }

        if (reviewType === ReviewTypeStrings.ThreatModelReview && teamMetadata?.mandateTMFile && dataDownloadableArtifacts && dataDownloadableArtifacts.length > 0) {
            var hasTm7orTm8 = dataDownloadableArtifacts.some(x => x.fileName?.toLowerCase()?.endsWith(".tm7") || x.fileName?.toLowerCase()?.endsWith(".tm8"))
            if (!hasTm7orTm8 && mandateTmFile) {
                displayMessagesSet([{ message: "Please attach a threat model file for Threat modeling review.", messageType: DisplayMessageType.Warning }]);
                return;
            }
        }

        let updatedReview: ScheduleMeetingMetadataUpdate = {
            interactingServices: selectedOtherInteractionServiceTreeServices,
            otherTeamMembers: dataOtherUsers,
            requestor: requestorUerProfile,
            technologyTags: selectedTechnologyTags,
            additionalDetails: additionalRequestDetails,
            reviewId: review?.id,
            serviceId: review?.service?.serviceId
        }

        var response = await reviewService.updateScheduleMeetingMetadata(updatedReview);
        if (response && !response.hasErrors && response.result) {
            reviewSet(response.result);
            displayMessagesSet([]);
            if (review?.reviewType === ReviewTypeStrings.ThreatModelReview && teamQuestionsList && teamQuestionsList.length > 0) {
                pivotIndexSet("pivot_2");
            }
            else {
                pivotIndexSet("pivot_3");

            }
        }
        else {
            if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
                displayMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
            }
            else {
                displayMessagesSet([{ message: "The review details could not be updated", messageType: DisplayMessageType.Error }]);
            }
        }
    }

    const onQuestionnaireSave = async () => {
        questionResponsesRef.current = [];
        displayMessagesSet([]);
        if (teamQuestionsList && teamQuestionsList.length > 0) {
            var isValidInput = validateQuestionsInput(teamQuestionsList);
            if (isValidInput) {
                teamQuestionsList.map(item => {
                    questionResponsesRef.current.push({
                        id: item.id,
                        questionNumber: item.questionNumber,
                        questionText: item.questionText,
                        questionType: item.questionType,
                        answerChoices: item.answerChoices,
                        answerText: item.answerText,
                        isRequiredQuestion: item.isRequiredQuestion,
                        selectedAnswerChoice: item.selectedAnswerChoice
                    })
                })

                let updatedReview: QuestionResponse = {
                    reviewId: review?.id,
                    serviceId: review?.service?.serviceId,
                    questionnaireId: questionnaireId,
                    questions: questionResponsesRef.current
                }

                var response = await reviewService.recordQuestionResponses(updatedReview);
                if (response && !response.hasErrors && response.result) {
                    reviewSet(response.result);
                    displayMessagesSet([]);
                    pivotIndexSet("pivot_3");
                }
                else {
                    if (response?.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message)
                        displayMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);

                }
            }
            else {
                displayMessagesSet([{ message: "Please answer all mandatory questions", messageType: DisplayMessageType.Error }]);
            }

        }
    }

    const validateQuestionsInput = (questions: Questions[]): boolean => {
        var isValidInput: boolean = true;
        if (questions && questions.length > 0) {
            questions.map(item => {
                if (item.isRequiredQuestion) {
                    if (item.questionType == QuestionType.FreeText && item.answerText == "")
                        isValidInput = false;
                    else if (item.questionType == QuestionType.Choice && item.selectedAnswerChoice == "")
                        isValidInput = false;
                    else if (item.questionType == QuestionType.FreeTextWithChoice && item.answerText == "" && item.selectedAnswerChoice == "")
                        isValidInput = false;
                }
            })
        }
        return isValidInput;
    }

    const onChoiceChange = (questionItem: Questions, selectedOption: IChoiceGroupOption): void => {
        if (selectedOption && selectedOption.key) {
            var queList = teamQuestionsList;
            if (queList && queList.length > 0) {
                const idx = queList.findIndex(item => item.id == questionItem.id);
                const nextData = queList.slice();
                const nextItem = { ...nextData[idx] };
                nextItem.selectedAnswerChoice = selectedOption.key;
                nextData[idx] = nextItem;
                setTeamQuestionsList(nextData);
            }
        }
    }

    const onQuestionTextChange = (questionItem: Questions, itemProperty: string, itemValue: string): void => {
        if (questionItem && teamQuestionsList) {
            var updatedList = teamQuestionsList.map(obj => {
                if (obj.id === questionItem.id && itemProperty == "answer") {
                    return { ...obj, answerText: itemValue };
                }

                return obj;
            });
            setTeamQuestionsList(updatedList);
        }
    }

    useEffect(() => {
        selectedTechnologyTags.forEach(x => {
            console.log(x);
        })
    }, [selectedTechnologyTags])

    const [isFetchingForDownload, isFetchingForDownloadSet] = useState<boolean>();
    const onArtifactClick = async (artifact: Artifact) => {
        if (artifact.artifactType == ArtifactTypeStrings.HyperLink) {
          displayMessagesSet([{ message: "The artifact is downloaded.", messageType: DisplayMessageType.Success }]);
        }
        if (artifact.artifactType == ArtifactTypeStrings.File) {
            isFetchingForDownloadSet(true);
            if (artifact.artifactId && artifact.serviceId) {
                var response = await artifactService.getArtifactSasUrl(artifact.artifactId, artifact.serviceId);
                if (response && !response.hasErrors && response.result && response.result.length > 0) {
                   displayMessagesSet([{ message: "The artifact is downloaded.", messageType: DisplayMessageType.Success }]);
                }
                else {
                    if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
                        displayMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Warning }]);
                    }
                    else {
                        displayMessagesSet([{ message: "The artifact could not be downloaded.", messageType: DisplayMessageType.Error }]);
                    }
                }
            }

            isFetchingForDownloadSet(false);
        }
    }

    const [displayMessages, displayMessagesSet] = useMessages();

    const [preventNavigation, preventNavigationSet] = useState(false);
    const [flag, setFlag, next] = useNavigationExitPrompt(preventNavigation);
    useEffect(() => {
        console.log(flag);
        if (flag) {
            var leave = window.confirm("Are you sure you want to leave?")
            if (leave) {
                deleteDraftReview();
            }
            else {
                setFlag(false);
            }
        }
    }, [flag]);

    const [isNextButtonVisible, isNextButtonVisibleSet] = useState<boolean>(true);
    const [isCancelButtonVisible, isCancelButtonVisibleSet] = useState<boolean>(true);
    const deleteDraftReview = async () => {
        if (review && review.id && review.service && review.service.serviceId) {
            await reviewService.deleteReview(review?.id, review?.service?.serviceId)
        }
        preventNavigationSet(false);
        next();
    }

    const navigateToCreateSchedule = async () => {
        navigate("/");
    }
    return (
        <div className="container" style={{ padding: 10 }}>
            <div className="row">
                <HeadingComponent title="Schedule Threat Model Review" subtitle="Book a slot with the security team" />
            </div>

            <div className="row">
                <MessageComponent messages={displayMessages} />
            </div>

            <div className="row">
                {/*   */}
                <Pivot aria-label="Schedule meeting" style={{ minHeight: 1076 }} selectedKey={String(pivotIndex)}>
                    <PivotItem headerText="Schedule Meeting" itemIcon="Calendar" itemKey="pivot_0">
                        <div className="row">
                            <div className="col">
                                <div className="row">
                                    <UserServicesComponent
                                        id="schedule_review_servicedropdown"
                                        label="Service"
                                        placeholder="Select a service"
                                        selectedServiceId={dataSelectedServiceTreeService?.serviceId}
                                        addOther={true}
                                        showP3P4ServicesOnly={false}
                                        showAllServices={false}
                                        onServiceSelected={(selectedServices?: ServiceTreeService | undefined) => {
                                            dataSelectedSlotSet(undefined);
                                            if (selectedServices) {
                                                dataSelectedServiceTreeServiceSet(selectedServices);
                                            }
                                            else {
                                                dataSelectedServiceTreeServiceSet(undefined);
                                                showOtherServiceSelectorSet(true);
                                            }
                                        }
                                        } />
                                </div>
                                {
                                    showOtherServiceSelector &&
                                    <div className="row">
                                        <Label htmlFor="ServiceChangePicker">
                                            Start typing the service name to search for your service
                                        </Label>
                                        <ServiceTreeServicePickerComponent
                                            itemLimit={1}
                                            placeholder="Select a service"
                                            id='schedule_review_servicepicker'
                                            SelectedServices={dataSelectedOtherServiceTreeService ? [dataSelectedOtherServiceTreeService] : []}
                                            onServiceSelected={(selectedServices) => {
                                                dataSelectedSlotSet(undefined);
                                                if (selectedServices && selectedServices.length > 0 && selectedServices[0]) {
                                                    dataSelectedOtherServiceTreeServiceSet(selectedServices[0]);
                                                }
                                            }} />
                                    </div>
                                }
                                <div className="row">
                                    <div className="col">
                                        <div className="row">
                                            <ServiceReviewTeamsComponent
                                                id="schedule_review_teampicker"
                                                label="Security Review Team"
                                                placeholder="Select a Review Team"
                                                service={selectedServiceTreeService}
                                                onReviewTeamSelected={(selectedTeam?: Team) => {
                                                    dataSelectedSlotSet(undefined);
                                                    if (selectedTeam) {
                                                        dataSelectedReviewTeamSet(selectedTeam);
                                                    }
                                                    else {
                                                        dataSelectedReviewTeamSet(undefined);
                                                    }
                                                }
                                                } />
                                        </div>
                                    </div>
                                    {dataSelectedReviewTeam && dataSelectedReviewTeam.teamDescription && (
                                        <div className="row">
                                            <Stack>
                                                <StackItem>
                                                    <Label title="reviewTeamBoldDescription" style={{ fontStyle: "italic", fontWeight: "bold" }}> {boldTeamDescription} </Label>
                                                </StackItem>
                                                <StackItem>
                                                    <Label title="reviewTeamDescription" style={{ fontStyle: "italic", fontWeight: 400 }}> {remainingTeamDescription} </Label>
                                                </StackItem>
                                            </Stack>
                                        </div>
                                    )}
                                </div>
                                <div className="row">
                                    { teamMetadata && 
                                        <ReviewTypeComponent
                                            id="schedule_review_reviewType"
                                            label="Review Type"
                                            selectedTmMeetingType={reviewType}
                                            showSecurityConsultation={teamMetadata?.allowSC !== undefined ? teamMetadata.allowSC : true}
                                            showThreatModelReview={teamMetadata?.allowTM !== undefined ? teamMetadata.allowTM : true}
                                            onReviewTypeSelected={function (selectedReviewType: string): void {
                                                reviewTypeSet(selectedReviewType);
                                            }} />
                                    }
                                </div>

                                {
                                    dataSelectedSlot &&
                                    <div className="row">
                                        <Label>Selected Slot Date and Time</Label>
                                        <div className="row">
                                            <Stack horizontal tokens={stackTokens}>
                                                <StackItem>
                                                    {getDateFormat(dataSelectedSlot.slotDateLocal) + '-' + getTimeFormat(dataSelectedSlot.slotDateLocal)}
                                                </StackItem>
                                                <StackItem>
                                                    <MeetingTimeCalloutComponent meetingData={dataSelectedSlot.meetingsData} scheduleName={dataSelectedSlot.scheduleName} />
                                                </StackItem>
                                            </Stack>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="col">
                                {
                                    dataSelectedReviewTeam &&
                                    <AvailableSlotsComponent
                                        id="schedule_review_availabilityCalendar"
                                        reviewTeam={dataSelectedReviewTeam}
                                        slotButtonText="Select Slot"
                                        onSlotSelcted={(selectedSlot: Slot) => {
                                            console.log(selectedSlot);
                                            dataSelectedSlotSet(selectedSlot);
                                        }} />
                                }
                            </div>

                        </div>

                        <div className="row">
                            <div className="d-flex" style={{ margin: 20, padding: 10 }}>
                                <div className="mx-auto">
                                    <Stack horizontal tokens={stackTokens}>
                                        <StackItem>
                                            {isNextButtonVisible &&
                                                <SpinnerPrimaryButton id="schedule_review_serviceslottabNext" onclick={async () => { isCancelButtonVisibleSet(false); await onNextClick(); isCancelButtonVisibleSet(true); }} text="Next" executingText="Saving the review information" />
                                            }
                                        </StackItem>
                                        <StackItem>
                                            {isCancelButtonVisible &&
                                                <SpinnerDefaultButton onclick={async () => { isNextButtonVisibleSet(false); navigateToCreateSchedule(); isNextButtonVisibleSet(true); }} text="Cancel" executingText="Cancelling" />
                                            }
                                        </StackItem>
                                    </Stack>
                                </div>
                            </div>
                        </div>
                    </PivotItem>
                    <PivotItem headerText="Metadata & Artifacts" itemIcon="CloudUpload" itemKey="pivot_1">
                        <div className="row">
                            <div className="col">
                                <div className="row">
                                    <Stack tokens={stackTokens} style={{ marginBottom: 5 }}>
                                        <StackItem >
                                            <Label title="Service Name">Service Name</Label>
                                        </StackItem>
                                        <StackItem>
                                            <Text>{selectedServiceTreeService?.serviceName}</Text>
                                        </StackItem>
                                    </Stack>
                                </div>
                                <div className="row">
                                    <MeetingRequestorComponent
                                        onUserSelected={(selectedUser: UserProfile) => {
                                            if (selectedUser) {
                                                requestorUerProfileSet(selectedUser)
                                            }
                                        }}
                                    />
                                </div>
                                <div className="row">
                                    <ServiceMemberPeoplePickerComponent
                                        id="schedule_review_ServiceMembers"
                                        itemLimit={50}
                                        serviceData={selectedServiceTreeService}
                                        onUserSelected={(selectedUsers: UserProfile[]) => {
                                            dataOtherUsersSet(selectedUsers);
                                        }
                                        } />
                                </div>

                                <div className="row">
                                    {teamMetadata?.enableTechTags && (
                                        <>
                                            <Label>Enter the technologies used in the App.</Label>
                                            <TechnologyTagsComponent
                                                selectedTechnologies={selectedTechnologyTags}
                                                onTechTagSelected={(selectedTags: TechTag[]) => {
                                                    selectedTechnologyTagsSet(selectedTags);
                                                }} />
                                        </>
                                    )}
                                </div>

                                <div className="row">
                                    <TextField
                                        id="schedule_review_AdditionalDetails"
                                        styles={{

                                        }}
                                        multiline={true}
                                        label="Additional Details"
                                        name="additionaldetails"
                                        value={additionalRequestDetails}
                                        onChange={onAdditionalDetailsChange}
                                    />
                                </div>


                            </div>
                            <div className="col">
                                <Label style={{ marginBottom: 20 }}>Upload Threat model file and other artifacts required for review.</Label>
                                {
                                    review && review.id && review.service && review.service.serviceId && review.service.serviceName &&
                                    <UploadOrLinkArtifactsComponent
                                        id="schedule_review_UploadOrLink"
                                        reviewId={review.id}
                                        serviceId={review.service.serviceId}
                                        serviceName={review.service.serviceName}
                                        onArtifactUploaded={(uploadedArtifacts: LinkedArtifact[]) => {
                                            if (uploadedArtifacts && uploadedArtifacts.length > 0) {
                                                loadArtifactsForDownload(uploadedArtifacts);
                                            }
                                        }}
                                        onArtifactLinked={(linkedArtifacts: LinkedArtifact[]) => {
                                            console.log("On Artifacts linked");
                                            if (linkedArtifacts && linkedArtifacts.length > 0) {
                                                loadArtifactsForDownload(linkedArtifacts);
                                            }
                                        }}
                                    />
                                }

                                <div className="row" style={{ marginTop: 30, marginBottom: 10, marginLeft: 5, marginRight: 5 }}>
                                    <div className="row">
                                        <div className="col">
                                            {
                                                isFetchingForDownload && <Spinner size={SpinnerSize.small} label="Loading file for download" />
                                            }
                                            <Stack>
                                                {
                                                    dataDownloadableArtifacts && dataDownloadableArtifacts.length > 0 &&
                                                    dataDownloadableArtifacts.map(artifact => {
                                                        return <StackItem key={"artifact_" + artifact.artifactId}>
                                                            <div className="d-flex">
                                                                <span style={{ marginTop: 5, marginRight: 10 }}>
                                                                    <FileTypeLogoComponent
                                                                        artifactType={artifact.artifactType as string}
                                                                        fileName={artifact.fileName}
                                                                    />
                                                                </span>
                                                                <Label className="me-auto">{artifact.artifactTitle}</Label>
                                                                <FontIcon iconName="CloudDownload" title={artifact.artifactUrl} style={{ color: 'blue', cursor: 'pointer' }} onClick={
                                                                    (e) => {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        onArtifactClick(artifact);
                                                                    }
                                                                } />

                                                            </div>
                                                        </StackItem>
                                                    })

                                                }
                                            </Stack>
                                        </div>
                                        <div className="col">
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            {
                                                isFetchingDownloadableArtifacts &&
                                                <Spinner title="Refreshing artifacts for download" label="Refreshing artifacts for download" size={SpinnerSize.small} />
                                            }
                                        </div>
                                        <div className="col"></div>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div className="row">
                            <div className="d-flex" style={{ margin: 20, padding: 10 }}>
                                <div className="mx-auto">

                                    <Stack horizontal tokens={stackTokens}>
                                        <StackItem>
                                            {isNextButtonVisible &&
                                                <SpinnerPrimaryButton id="schedule_review_MetadataArtifactsNext" onclick={async () => { isCancelButtonVisibleSet(false); await onNextMetadataNextClick(); isCancelButtonVisibleSet(true); }} text="Next" executingText="Saving the review information" />
                                            }
                                        </StackItem>
                                        <StackItem>
                                            {isCancelButtonVisible &&
                                                <SpinnerDefaultButton onclick={async () => { isNextButtonVisibleSet(false); await deleteDraftReview(); navigateToCreateSchedule(); isNextButtonVisibleSet(true); }} text="Cancel" executingText="Cancelling" />
                                            }
                                        </StackItem>
                                    </Stack>

                                    <div className="row">
                                        <Text variant="smallPlus" style={{ marginTop: 10 }}>* Click cancel if you want to abandon this booking session</Text>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </PivotItem>
                    {
                        teamMetadata && teamMetadata.enableQuestionnaire && teamQuestionsList && teamQuestionsList.length > 0 && reviewType != undefined && reviewType.length > 0 && reviewType === ReviewTypeStrings.ThreatModelReview && (
                            <PivotItem headerText="Questionnaire" itemIcon="SurveyQuestions" itemKey="pivot_2">
                                <div className="row">
                                    <Stack tokens={stackTokens} style={{ marginBottom: 5 }}>
                                        <StackItem >
                                            <Label title="Service Name">Service Name</Label>
                                        </StackItem>
                                        <StackItem>
                                            <Text>{selectedServiceTreeService?.serviceName}</Text>
                                        </StackItem>
                                    </Stack>
                                </div>
                                <div className="row">
                                    <PreviewQuestionnaire isDesign={false} isPreview={false} isUserView={true} questionsList={teamQuestionsList} onEditQuestion={onQuestionTextChange} onSelectChoice={onChoiceChange} />
                                </div>
                                <div className="row" style={{ marginBottom: 10 }}>
                                    <div style={{ padding: 0, marginLeft: 45 }}>
                                        <div className="d-flex">
                                            <div className="mx-auto">
                                                <Stack tokens={stackTokens} horizontal >
                                                    <StackItem>
                                                        {isNextButtonVisible &&
                                                            <SpinnerPrimaryButton id="schedule_review_QuestionnaireNext" text="Next" executingText="Saving your responses" onclick={async () => { isCancelButtonVisibleSet(false); await onQuestionnaireSave(); isCancelButtonVisibleSet(true); }} />
                                                        }
                                                    </StackItem>
                                                    <StackItem>
                                                        {isCancelButtonVisible &&
                                                            <SpinnerDefaultButton onclick={async () => { isNextButtonVisibleSet(false); await deleteDraftReview(); navigateToCreateSchedule(); isNextButtonVisibleSet(true); }} text="Cancel" executingText="Cancelling" />
                                                        }
                                                    </StackItem>
                                                </Stack>
                                                <div className="row">
                                                    <Text variant="smallPlus" style={{ marginTop: 10 }}>* Click cancel if you want to abandon this booking session</Text>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </PivotItem>
                        )}
                    <PivotItem headerText="Summary" itemIcon="DocumentApproval" itemKey="pivot_3">
                        {
                            review && review.service?.serviceId && review.easyReviewId && review.easyReviewId.length > 0 ? <MessageBar
                                messageBarType={MessageBarType.success}
                                isMultiline={false}>
                                The booking completed successfully.
                                <Link id="schedule_review_reviewdetailhyperlink" to={"/reviewdetail/" + review.id + "/" + review.service?.serviceId} >
                                    {

                                        review.easyReviewId
                                    }
                                    {console.log("I am invoked")}
                                </Link>
                            </MessageBar> : <MessageBar messageBarType={MessageBarType.error}>
                                Some error occurred.
                            </MessageBar>
                        }
                    </PivotItem>
                </Pivot>
            </div>
        </div>
    )
}


