import { Callout, ComboBox, CommandBarButton, DefaultButton, FontIcon, IBasePicker, IBasePickerSuggestionsProps, IComboBox, IComboBoxOption, ITag, Label, Spinner, Stack, StackItem, TagPicker, Text, TextField, ValidationState } from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { Editor } from "@tinymce/tinymce-react";
import { createRef, FC, useContext, useEffect, useState } from "react";
import { WeaknessType } from "../../Models/WeaknessType";
import { ReviewWorkItem } from "../../Models/WorkItems/ReviewWorkItem";
import MeetingsService from "../../Services/MeetingsService";
import { isEmptyOrSpaces } from "../../Utilities/textUtilities";
import { store } from "./GlobalStateProvider";
import { DisplayMessageType, MessageComponent, useMessages } from "./MessageComponent";
import { SpinnerDefaultButton } from "./SpinnerDefaultButton";
import { SpinnerPrimaryButton } from "./SpinnerPrimaryButton";

interface ITasksBugsCreateComponent {
    showInline: boolean,
    onAddtoDraft?: (wkItem: ReviewWorkItem) => void,
    onPublishtoADO?: (wkItem: ReviewWorkItem) => void,
    onCloseButtonClick?: (isPublishOrAddDraftClicked: boolean) => void,
    onTaskTypeChange?: (taskType:string)=> void,
    selectedWorkItem?: ReviewWorkItem,
    weaknessTypes?: WeaknessType[],
}

export const TasksBugsCreateComponent: FC<ITasksBugsCreateComponent> = (props: ITasksBugsCreateComponent): JSX.Element => {

    let reviewService = new MeetingsService();
    const stackTokens = { childrenGap: 10, };
    var { appState, appdispatch } = useContext(store);

    const loadWeaknessTypes = async () => {
        var response = await reviewService.getWeaknesses();
        if (response && !response.hasErrors && response.result && response.result.length > 0) {
            dataWeaknessTypesSet(response.result);
        }
        else {
            if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
                displayTaskorBugMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
            }
            else {
                displayTaskorBugMessagesSet([{ message: "Could not load weakness types.", messageType: DisplayMessageType.Error }]);
            }
        }
    }

    const [isPublishOrAddDraftClicked, isPublishOrAddDraftClickedSet] = useState<boolean>(false);
    const [taskOrBugReferenceId, taskOrBugReferenceIdSet] = useState<string>();
    const [createdBy, createdBySet] = useState<string>();
    const [cmbTaskTypeOptions, cmbTaskTypeOptionsSet] = useState<IComboBoxOption[]>([
        {
            key: 'Bug',
            text: 'Bug'
        },
        {
            key: 'Task',
            text: 'Task'
        },
    ]);
    const onTaskTypeChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number): void => {
        if (option && option.key) {
            cmbSelectedTaskTypeOptionSet(option.key);

            if (props.onTaskTypeChange)
            {
                props.onTaskTypeChange(option.key as string);
            }
        }
    };
    const [cmbSelectedTaskTypeOption, cmbSelectedTaskTypeOptionSet] = useState<string | number>()

    const [cmbRiskRatingOptions, cmbRiskRatingOptionsSet] = useState<IComboBoxOption[]>([
        {
            key: '1',
            text: '1 - Critical'
        },
        {
            key: '2',
            text: '2 - Important'
        },
        {
            key: '3',
            text: '3 - Moderate'
        },
        {
            key: '4',
            text: '4 - Low'
        },
        {
            key: '5',
            text: '5 - Defense in Depth'
        },
    ]);
    const [cmbSelectedRiskRatingOption, cmbSelectedRiskRatingOptionSet] = useState<string | number>()
    const onRiskRatinChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number): void => {
        if (option) {
            cmbSelectedRiskRatingOptionSet(option.key);
        }
    };

    const [taskTitle, taskTitleSet] = useState<string>();
    const onTaskTitleChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        taskTitleSet(newValue);
    }

    const [taskDescription, taskDescriptionSet] = useState<string>();
    const onTaskDescriptionChange = (content: string, editor: any): void => {
        taskDescriptionSet(content);
    }
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const buttonId = useId('callout-button');

    const [dataWeaknessTypes, dataWeaknessTypesSet] = useState<WeaknessType[]>([]);
    const [dataWeaknessSelectedType, dataWeaknessSelectedTypeSet] = useState<WeaknessType>();
    const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: 'Suggestions',
        noResultsFoundText: 'No matching weakness found',
    };
    const [selectedWeaknessType, selectedWeaknessTypeSet] = useState<ITag[] | undefined>([]);

    const filterSuggestedTags = async (filter: string, selectedItems?: ITag[] | undefined): Promise<ITag[]> => {
        var weaknessSuggesstions: ITag[] = [];
        if (dataWeaknessTypes && dataWeaknessTypes.length > 0) {
            var filteredResults = dataWeaknessTypes.filter(x => x.fullName?.toLocaleLowerCase().startsWith(filter.toLocaleLowerCase()));
            if (filteredResults && filteredResults.length > 0) {
                filteredResults.forEach(x => {
                    if (x.id && x.fullName) {
                        weaknessSuggesstions.push({
                            key: x.id,
                            name: x.fullName
                        });
                    }
                })
            }
        }

        return weaknessSuggesstions;
    };
    const onWeaknessChanged = (items?: ITag[] | undefined) => {
        console.log("vvvvvvvvvvvvvvvvvvvvvvvvvv");
        console.log(items);
        if (items && items.length > 0) {
            selectedWeaknessTypeSet([items[items.length - 1]]);
            dataWeaknessSelectedTypeSet({
                id: items[items.length - 1].key as string,
                fullName: items[items.length - 1].name,
            });

        }
        else {
            selectedWeaknessTypeSet([]);
        }
    }
    const onWeaknessSelected = (selectedItem?: ITag | undefined): ITag => {
        if (selectedItem) {
            var weakness = dataWeaknessTypes.filter(x => x.id === selectedItem.key);
            if (weakness && weakness.length > 0) {
                selectedWeaknessTypeSet([selectedItem]);
                dataWeaknessSelectedTypeSet(weakness[0]);
            }
        }

        return selectedItem!;
    }

    const [displayTaskorBugMessages, displayTaskorBugMessagesSet] = useMessages();

    const validTaskOrBug = (): boolean => {

        if (cmbSelectedTaskTypeOption === undefined || isEmptyOrSpaces(cmbSelectedTaskTypeOption as string)) {
            displayTaskorBugMessagesSet([{ message: "Task type is required field", messageType: DisplayMessageType.Warning }]);
            return false;
        }

        if (cmbSelectedRiskRatingOption === undefined || isEmptyOrSpaces(cmbSelectedRiskRatingOption as string)) {
            displayTaskorBugMessagesSet([{ message: "Risk rating is required field", messageType: DisplayMessageType.Warning }]);
            return false;
        }

        if (dataWeaknessSelectedType === undefined || dataWeaknessSelectedType.id === undefined) {
            displayTaskorBugMessagesSet([{ message: "Weakness type is required field", messageType: DisplayMessageType.Warning }]);
            return false;
        }

        if (taskTitle === undefined && isEmptyOrSpaces(taskTitle)) {
            displayTaskorBugMessagesSet([{ message: "Task title is required field", messageType: DisplayMessageType.Warning }]);
            return false;
        }

        if (isEmptyOrSpaces(taskDescription)) {
            displayTaskorBugMessagesSet([{ message: "Task description is required field", messageType: DisplayMessageType.Warning }]);
            return false;
        }

        displayTaskorBugMessagesSet([]);
        return true;
    }

    const onTaskAddClicked = async () => {
        let refId = crypto.randomUUID().toString();
        if (taskOrBugReferenceId) {
            refId = taskOrBugReferenceId;
        }

        let wCreatedBy = appState?.userProfile?.userPrincipalName;
        if (createdBy) {
            wCreatedBy = createdBy
        }

        let wkItem: ReviewWorkItem = {
            title: taskTitle,
            description: taskDescription,
            riskRating: cmbSelectedRiskRatingOption as number,
            type: cmbSelectedTaskTypeOption as string,
            weaknessType: dataWeaknessSelectedType?.fullName,
            referenceId: refId,
            modifiedBy: appState?.userProfile?.userPrincipalName,
            createdBy: wCreatedBy,
        };

        if (props.onAddtoDraft) {

            await props.onAddtoDraft(wkItem);
            resetCreateTaskBugUI();
        }
    };

    const onTaskOrBugPublishClicked = async () => {
        var isValidaResult = validTaskOrBug();
        if (!isValidaResult) {
            return;
        }

        let wCreatedBy = appState?.userProfile?.userPrincipalName;
        if (createdBy) {
            wCreatedBy = createdBy
        }


        let refId = taskOrBugReferenceId;
        if (refId === undefined)
        {
            refId = crypto.randomUUID();
        } 
        let wkItem: ReviewWorkItem = {
            title: taskTitle,
            description: taskDescription,
            riskRating: cmbSelectedRiskRatingOption as number,
            type: cmbSelectedTaskTypeOption as string,
            weaknessType: dataWeaknessSelectedType?.fullName,
            referenceId: refId,
            modifiedBy: appState?.userProfile?.userPrincipalName,
            createdBy: wCreatedBy,
        };

        if (props.onPublishtoADO) {
            await props.onPublishtoADO(wkItem);
        }

        resetCreateTaskBugUI();
    }

    function resetCreateTaskBugUI() {
        taskTitleSet("");
        taskDescriptionSet("");
        cmbSelectedRiskRatingOptionSet("");
        cmbSelectedTaskTypeOptionSet("");
        selectedWeaknessTypeSet([]);
        dataWeaknessSelectedTypeSet(undefined);
        taskOrBugReferenceIdSet(undefined);
        createdBySet(undefined);

        isPublishOrAddDraftClickedSet(false);
    }

    useEffect(() => {

        // Added weakness types as prop to avoid multiple calls to the server.
        if (props.weaknessTypes && props.weaknessTypes.length > 0) {
            dataWeaknessTypesSet(props.weaknessTypes);
        }
        else {
            loadWeaknessTypes();
        }
    }, []);

    useEffect(() => {
        if (props.selectedWorkItem) {
            if (props.selectedWorkItem.title) {
                taskTitleSet(props.selectedWorkItem.title);
            }

            if (props.selectedWorkItem.referenceId) {
                taskOrBugReferenceIdSet(props.selectedWorkItem.referenceId)
            }
            else {
                taskOrBugReferenceIdSet(undefined);
            }

            if (props.selectedWorkItem.description) {
                taskDescriptionSet(props.selectedWorkItem.description);
            }

            if (props.selectedWorkItem.riskRating) {

                cmbSelectedRiskRatingOptionSet(props.selectedWorkItem.riskRating.toString());
            }

            if (props.selectedWorkItem.type) {
                cmbSelectedTaskTypeOptionSet(props.selectedWorkItem.type);
            }

            if (props.selectedWorkItem.weaknessType) {
                var weakNessType = dataWeaknessTypes.filter(x => x.fullName === props.selectedWorkItem?.weaknessType)
                if (weakNessType && weakNessType.length > 0) {
                    dataWeaknessSelectedTypeSet(weakNessType[0]);
                    if (weakNessType[0].id && weakNessType[0].fullName) {
                        let wkt: ITag = {
                            key: weakNessType[0].id,
                            name: weakNessType[0].fullName
                        };
                        selectedWeaknessTypeSet([wkt])
                   }
                }
                else {
                    var randomId = crypto.randomUUID();
                    selectedWeaknessTypeSet([{ key: randomId, name: props.selectedWorkItem.weaknessType }])
                    dataWeaknessSelectedTypeSet({ id: randomId, fullName: props.selectedWorkItem.weaknessType})
                }
            }

            if (props.selectedWorkItem.createdBy) {
                createdBySet(appState.userProfile?.userPrincipalName);
                if (props.selectedWorkItem.createdBy !== appState.userProfile?.userPrincipalName) {
                    taskOrBugReferenceIdSet(crypto.randomUUID());
                }
            }
        }
    }, [props.selectedWorkItem])

    const tagPickerRef = createRef<IBasePicker<ITag>>();
    const pickerWeaknessSuggestionsProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: 'Select or Add new',
        noResultsFoundText: 'No results found!',
        onRenderNoResultFound: () => {
            return (
                <Stack tokens={stackTokens}>
                    <Text variant="small">Type your tag and click add or hit 'Enter'</Text>
                    <CommandBarButton iconProps={{ iconName: 'Add' }} text="Add new tag" onClick={() => {
                        tagPickerRef.current?.completeSuggestion(true);
                    }} />
                </Stack>
            );
        }
    };
    const getWeaknessTypeTagTextFromItem = (item: ITag) => item.name;
    function validateTechnologyTag(input: string): ValidationState {
        if (input.length > 0) {
            return ValidationState.valid;
        } else if (input.length > 100) {
            return ValidationState.warning;
        } else {
            return ValidationState.invalid;
        }
    }

    return (
        <div className="row">
            <div className="row">
                <MessageComponent messages={displayTaskorBugMessages} />
            </div>
            <div className="row">
                <div className="col">
                    <ComboBox
                        label="Type"
                        options={cmbTaskTypeOptions}
                        selectedKey={cmbSelectedTaskTypeOption}
                        useComboBoxAsMenuWidth={true}
                        onChange={onTaskTypeChange}
                    />
                </div>
                <div className="col">
                    <ComboBox
                        label="Risk Rating"
                        options={cmbRiskRatingOptions}
                        selectedKey={cmbSelectedRiskRatingOption}
                        useComboBoxAsMenuWidth={true}
                        onChange={onRiskRatinChange}
                    />
                </div>

                {
                    props.showInline &&
                    <div className="col-4">
                        <Stack horizontal tokens={stackTokens} >
                            <SpinnerPrimaryButton text="Add to draft tasks and bugs" onclick={onTaskAddClicked} executingText={"Saving to Drafts"} style={{ marginTop: 30 }} />
                        </Stack>
                    </div>
                }
            </div>
            <div className="row">
                <div >
                    <Stack horizontal>
                        <StackItem>
                            <Label htmlFor="picker1">
                                WeaknessType
                            </Label>
                        </StackItem>
                        <StackItem style={{ marginTop: 5, marginLeft: 5 }}>
                            <FontIcon iconName="Info" color="blue" style={{ cursor: "pointer", color: 'blue' }} onClick={toggleIsCalloutVisible} id={buttonId} />
                            {
                                isCalloutVisible &&
                                <Callout
                                    role="dialog"
                                    target={`#${buttonId}`}
                                    onDismiss={toggleIsCalloutVisible}
                                    setInitialFocus>
                                    {
                                        <Text block variant="small" >
                                            Type-ahead input field. Type weakness type as per this <a href='https://microsoft.sharepoint.com/:x:/t/AzureSecurityAssurance/EfxhYlJ33CdGpJEcPE7U6oIBOcUZcEsym0ymR425JYGNUQ?e=Wniuvd' target='_blank'>doc</a>.
                                        </Text>
                                    }
                                </Callout>
                            }
                        </StackItem>
                    </Stack>
                    {/* <TagPicker
                        componentRef={tagPickerRef}
                        removeButtonAriaLabel="Remove"
                        selectionAriaLabel="Selected Service"
                        resolveDelay={400}
                        onResolveSuggestions={filterSuggestedTags}
                        selectedItems={selectedWeaknessType}
                        onChange={onWeaknessChanged}
                        onItemSelected={onWeaknessSelected}
                        itemLimit={1}
                        pickerSuggestionsProps={pickerWeaknessSuggestionsProps}
                        getTextFromItem={getWeaknessTypeTagTextFromItem}
                        inputProps={{
                            id: 'picker1',
                            placeholder: 'Enter weakness type'
                        }}
                        createGenericItem={(input: string) => {
                            console.log("--generic " + input);
                            return { key: crypto.randomUUID(), name: input } as ITag;
                        }}
                    /> */}

                    <TagPicker
                        componentRef={tagPickerRef}
                        aria-label="Enter weakness type"
                        selectedItems={selectedWeaknessType}
                        onResolveSuggestions={filterSuggestedTags}
                        getTextFromItem={getWeaknessTypeTagTextFromItem}
                        pickerSuggestionsProps={pickerWeaknessSuggestionsProps}
                        inputProps={{
                            id: 'picker1',
                            placeholder: 'Enter weakness type'
                        }}
                        onChange={onWeaknessChanged}
                        createGenericItem={(input: string) => {
                            console.log("--generic " + input);
                            let tag: ITag = { key: crypto.randomUUID(), name: input };
                            selectedWeaknessTypeSet([tag])
                            return tag;

                        }}
                        onValidateInput={validateTechnologyTag}
                    />
                </div>
            </div>
            <div className="row">
                <TextField label="Task Title" value={taskTitle} onChange={onTaskTitleChange} />
            </div>
            <div className="row">
                <Label>Task Description</Label>
                {/* <TextField label="Task Description" multiline={true} style={{ height: 150 }} value={taskDescription} onChange={onTaskDescriptionChange} /> */}
                <div style={{ padding: 10 }}>
                    {/* @ts-ignore*/}
                    <Editor
                        init={{
                            skin: false,
                            content_css: false,
                            height: 350,
                            menubar: false,
                            statusbar: false,
                            paste_data_images: true,
                            plugins: [
                                'link lists advlist table paste charmap hr'
                            ],
                            toolbar1:
                                'undo redo | bold italic underline| bullist numlist | backcolor forecolor | outdent indent | link charmap',
                            table_default_attributes: {
                                border: '1'
                            },

                        }}
                        value={taskDescription}
                        onEditorChange={onTaskDescriptionChange}
                        disabled={false}
                    />
                </div>
            </div>

            {
                !props.showInline &&
                <div className="row" style={{ marginTop: 30 }}>
                    <Stack horizontal tokens={stackTokens}>
                        <SpinnerPrimaryButton text="Publish to ADO" onclick={async () => { isPublishOrAddDraftClickedSet(true); await onTaskOrBugPublishClicked() }} executingText={"Publishing Task to ADO"} />
                        <SpinnerDefaultButton text="Add to draft(s)" onclick={async () => { isPublishOrAddDraftClickedSet(true); await onTaskAddClicked() }} executingText={"Saving to Drafts"} />
                        <DefaultButton text="Close" onClick={() => { if (props.onCloseButtonClick) { props.onCloseButtonClick(isPublishOrAddDraftClicked); resetCreateTaskBugUI(); } }} />
                    </Stack>
                </div>
            }
        </div>
    );
}